<template>
  <div class="row mt-4">
    <div class="col-12 col-md">
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Agregar nuevo video" active>
          <b-form @submit.prevent="addVideoSoporte">
            <!-- title -->
            <b-form-group id="title" label-for="title">
              <template #label>
                <em class="text-danger font-weight-bold">Título: </em>
              </template>
              <b-form-input
                id="title"
                v-model="newVideoSoporte.titulo"
                placeholder="Titulo del video"
                required
              ></b-form-input>
            </b-form-group>
            <!-- descripcion -->
            <b-form-group id="textarea" label-for="textarea">
              <template #label>
                <em class="text-danger font-weight-bold">Descripción: </em>
              </template>
              <b-form-textarea
                id="textarea"
                v-model="newVideoSoporte.descripcion"
                placeholder="Descripcion del video..."
                rows="3"
                max-rows="6"
                required
              ></b-form-textarea>
            </b-form-group>
            <!-- url video -->
            <b-form-group id="urlVideo" label-for="urlVideo">
              <template #label>
                <em class="text-danger font-weight-bold">URL video: </em>
              </template>
              <b-form-input
                type="url"
                id="urlVideo"
                v-model="newVideoSoporte.url"
                placeholder="URL del video"
                required
              ></b-form-input>
            </b-form-group>
            <!-- btn submit -->
            <div class="d-flex justify-content-end">
              <vs-button
                animation-type="scale"
                :loading="isLoading"
                danger
                 
                size="large"
                style="min-width: 150px"
              >
                <i class="fas fa-circle-plus"></i>
                <template #animate> Agregar video </template>
              </vs-button>
            </div>
          </b-form>
        </b-tab>
        <b-tab title="Listado de videos" @click="onResize">
          <b-form-group id="filterForTitle" label-for="filterForTitle">
            <template #label>
              <em class="text-danger font-weight-bold">Filtrar por título: </em>
            </template>
            <b-form-input
              type="search"
              id="filterForTitle"
              placeholder="Empieza la busquedad"
              v-model="searchedTitle"
              required
            ></b-form-input>
          </b-form-group>
          <!-- lista de videos de soporte -->
          <div class="row list-videos justify-content-center mt-2 py-3">
            <div
              v-for="(video, index) in filterVideosSoportes"
              :key="video.id"
              class="col-12 col-md-5 d-flex align-items-center py-2 bg-dark rounded mt-3"
              :style="{
                transition: 'box-shadow 0.3s ease',
                boxShadow:
                  activeCardVideoSoporte == index
                    ? 'inset 0 0 0 2px #186bdd '
                    : 'none',
                cursor: 'default',
              }"
              @click="cardActive(index)"
            >
              <i
                class="fas fa-video position-absolute bg-primary rounded-circle"
                style="
                  font-size: 1.6rem;
                  top: 0;
                  left: 50%;
                  width: 50px;
                  height: 50px;
                  display: grid;
                  place-items: center;
                  transform: translate(-50%, -50%);
                "
              ></i>
              <!-- acciones update and delete -->
              <b-dropdown
                class="position-absolute"
                style="top: 0; right: 0"
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <i class="fas fa-ellipsis" style="font-size: 1.5rem"></i>
                </template>
                <b-dropdown-item-button v-b-modal="'modalUpdateSoporte'"
                  ><i class="fas fa-edit"></i> Editar</b-dropdown-item-button
                >
                <b-dropdown-item-button @click="deleteVideoSoporte(video.id)"
                  ><i class="fas fa-trash text-danger"></i>
                  <span class="text-danger ml-1"
                    >Eliminar</span
                  ></b-dropdown-item-button
                >
              </b-dropdown>
              <div class="mt-4 content-desc overflow-hidden">
                <span class="font-weight-bold h4 text-capitalize">{{
                  video.titulo
                }}</span>
                <p class="text-white-50 custom-descripcion">
                  {{ video.descripcion }}
                </p>
                <vs-tooltip
                  v-if="showReadMoreDesc[index]"
                  class="d-inline"
                  right
                >
                  <small class="text-capitalize font-italic text-primary">
                    leer más
                  </small>
                  <template #tooltip>
                    <div class="content-tooltip text-left overflow-hidden">
                      <p class="text-break">
                        {{ video.descripcion }}
                      </p>
                    </div>
                  </template>
                </vs-tooltip>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </div>
    <!-- preview url video-->
    <div
      class="col-12 col-md d-flex justify-content-center align-items-center bg-dark rounded mt-3 mt-md-0"
    >
      <div
        class="vista-previa d-flex justify-content-center align-items-center rounded"
      >
        <h5>Vista previa</h5>
        <modalIframe
          :url="
            activeCardVideoSoporte != null
              ? filterDataModalUpdateSoporteVideo.url
              : newVideoSoporte.url
          "
          :likeElement="true"
        ></modalIframe>
      </div>
    </div>
    <!-- update video soporte modal -->
    <b-modal
      id="modalUpdateSoporte"
      body-bg-variant=""
      hide-footer
      hide-header
      size="lg"
      centered
      body-class="body-modal-custom"
    >
      <b-form
        class="row flex-column-reverse flex-lg-row"
        @submit.prevent="updateVideoSoporte"
      >
        <div class="col-12 col-lg mt-2 mt-lg-0">
          <!-- title -->
          <b-form-group id="title" label-for="title">
            <template #label>
              <em class="text-danger font-weight-bold">Título: </em>
            </template>
            <b-form-input
              id="title"
              v-model="filterDataModalUpdateSoporteVideo.titulo"
              placeholder="Titulo del video"
              required
            ></b-form-input>
          </b-form-group>
          <!-- descripcion -->
          <b-form-group id="textarea" label-for="textarea">
            <template #label>
              <em class="text-danger font-weight-bold">Descripción: </em>
            </template>
            <b-form-textarea
              id="textarea"
              v-model="filterDataModalUpdateSoporteVideo.descripcion"
              placeholder="Descripcion del video..."
              rows="3"
              max-rows="6"
              required
            ></b-form-textarea>
          </b-form-group>
          <!-- url video soporte -->
          <b-form-group id="urlVideo" label-for="urlVideo">
            <template #label>
              <em class="text-danger font-weight-bold">URL video: </em>
            </template>
            <b-form-input
              type="url"
              id="urlVideo"
              v-model="filterDataModalUpdateSoporteVideo.url"
              placeholder="URL del video"
              required
            ></b-form-input>
          </b-form-group>
          <!-- btn submit -->
          <div class="mt-2 d-flex justify-content-end">
            <vs-button
              animation-type="scale"
              :loading="isLoading"
              danger
               
              size="large"
              style="min-width: 150px"
            >
              <i class="fas fa-circle-check"></i>
              <template #animate> Actualizar video </template>
            </vs-button>
          </div>
        </div>

        <!-- preview url video update-->
        <div
          class="col-12 col-lg d-flex justify-content-center align-items-center bg-dark rounded mr-lg-3"
        >
          <div
            class="vista-previa d-flex justify-content-center align-items-center rounded"
          >
            <h5>Vista previa</h5>
            <modalIframe
              :url="filterDataModalUpdateSoporteVideo.url"
              :likeElement="true"
            ></modalIframe>
          </div>
        </div>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import modalIframe from "@/components/helexium/modalIframe.vue";
const Swal = require("sweetalert2");
export default {
  name: "Soporte_component",
  components: {
    modalIframe,
  },
  data() {
    return {
      isLoading: false,
      showReadMoreDesc: {},
      searchedTitle: "",
      activeCardVideoSoporte: null,
      listaVideosSoportes: [],
      filterDataModalUpdateSoporteVideo: {
        id: "",
        titulo: "",
        descripcion: "",
        url: "",
      },
      newVideoSoporte: {
        titulo: "",
        descripcion: "",
        url: "",
      },
    };
  },
  mounted() {
    this.getListVideosSoportes();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.listaVideosSoportes.forEach((_, index) => {
        this.checkDescripcionLength(index);
      });
    },
    checkDescripcionLength(index) {
      this.$nextTick(() => {
        const parrafoElement =
          this.$el.querySelectorAll(".content-desc p")[index];
        if (parrafoElement) {
          console.log("Client Height: ", parrafoElement.clientHeight);
          console.log("Scroll Height: ", parrafoElement.scrollHeight);

          if (parrafoElement.scrollHeight > parrafoElement.clientHeight) {
            this.$set(this.showReadMoreDesc, index, true);
          } else {
            this.$set(this.showReadMoreDesc, index, false);
          }
        }
      });
      return false;
    },
    async getListVideosSoportes() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: "CapacitacionCRUD/ListCapacitacion/all",
        });
        console.log("list videos soportes...", res);
        if (res.length > 0) {
          this.listaVideosSoportes = res;
        } else {
          this.listaVideosSoportes = [];
        }
      } catch (error) {
        this.listaVideosSoportes = [];
        console.log("err", error);
      }
    },
    async addVideoSoporte() {
      try {
        this.isLoading = !this.isLoading;
        this.activeCardVideoSoporte = null;
        this.initDataUpdateVideoSoporte();
        let res = await this.$store.dispatch("hl_post", {
          path: "CapacitacionCRUD/",
          data: this.newVideoSoporte,
        });
        console.log("video add...", res);
        if (res.id) {
          this.listaVideosSoportes = [
            ...this.listaVideosSoportes,
            {
              ...this.newVideoSoporte,
              id: res.id,
            },
          ];
          this.isLoading = !this.isLoading;
          this.initFormNewVideoSoporte();
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro guardado correctamente!",
          });
        }
      } catch (error) {
        console.log("err", error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
      }
    },
    cardActive(index) {
      // Cambiar la tarjeta activa
      this.activeCardVideoSoporte =
        this.activeCardVideoSoporte === index ? null : index;

      // Realizar una copia profunda de los datos
      this.filterDataModalUpdateSoporteVideo = {
        ...this.listaVideosSoportes[index],
      };

      console.log(this.filterDataModalUpdateSoporteVideo);
    },
    updateVideoSoporte() {
      this.isLoading = !this.isLoading;
      this.$store.getters
        .fetchPut({
          path: `CapacitacionCRUD/${this.filterDataModalUpdateSoporteVideo.id}`,
          data: this.filterDataModalUpdateSoporteVideo,
        })
        .then((response) => response.json())
        .then((result) => {
          let index = this.listaVideosSoportes.findIndex(
            (video) => video.id == this.filterDataModalUpdateSoporteVideo.id
          );
          this.listaVideosSoportes.splice(index, 1, result);
          this.searchedTitle = "";
          this.activeCardVideoSoporte = null;
          this.isLoading = !this.isLoading;
          this.onResize();
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro actualizado correctamente!",
          });
        })
        .catch((error) => {
          console.log(error);
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-xmark'></i>`,
            color: "danger",
            position: "top-center",
            title: "Ha ocurrido un error!",
            text: error,
          });
        });
    },
    initFormNewVideoSoporte() {
      this.newVideoSoporte.titulo = "";
      this.newVideoSoporte.descripcion = "";
      this.newVideoSoporte.url = "";
    },
    initDataUpdateVideoSoporte() {
      this.filterDataModalUpdateSoporteVideo = {
        ...this.filterDataModalUpdateSoporteVideo,
        id: "",
        titulo: "",
        descripcion: "",
        url: "",
      };
    },
    deleteVideoSoporte(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "#F2135D",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.$store.getters
            .fetchDelete({ path: `CapacitacionCRUD/${id}` })
            .then((response) => response.json())
            .then((result) => {
              let index = this.listaVideosSoportes.findIndex(
                (video) => video.id == id
              );
              this.listaVideosSoportes.splice(index, 1);
              console.log(result);
              this.activeCardVideoSoporte = null;
              this.initDataUpdateVideoSoporte();
              this.$vs.notification({
                //duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-circle-check'></i>`,
                color: "primary",
                position: "top-right",
                title: "Registro eliminado correctamente!",
              });
            })
            .catch((err) => {
              console.log(err);
              this.$vs.notification({
                // duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-xmark'></i>`,
                color: "danger",
                position: "top-right",
                title: "Ha ocurrido un error!",
                text: err,
              });
            });
        }
      });
    },
  },
  computed: {
    filterVideosSoportes() {
      return this.listaVideosSoportes.filter((video) =>
        video.titulo.toLowerCase().includes(this.searchedTitle.toLowerCase())
      );
    },
  },
};
</script>

<style scoped>
.vista-previa {
  position: relative;
  width: 100%;
  min-height: 300px;
}

.list-videos {
  max-height: 400px;
  overflow-y: scroll;
  gap: 1rem;
}
/* parrafo corto */
.custom-descripcion {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
}
</style>
