<template>
  <div class="row mt-4">
    <div class="col-12 col-md">
      <b-tabs content-class="mt-3" justified>
        <b-tab title="Agregar nuevo curso" active>
          <b-form class="row" @submit.prevent="addCursoAcademia">
            <div
              class="col-12 col-lg mt-2 d-flex flex-column justify-content-center align-items-center"
            >
              <label for=" " class="form-label">
                <em class="text-danger font-weight-bolder"
                  >Imagen vista previa:</em
                >
              </label>
              <!-- image preview -->
              <div class="image-container w-100 rounded bg-dark">
                <input
                  type="file"
                  ref="fileInput"
                  @change="previewImage"
                  class="file-input"
                  @click.stop
                />
                <div
                  v-if="!imagePreview"
                  class="placeholder"
                  @click="openFileInput"
                >
                  Haz clic para seleccionar una imagen
                </div>
                <img
                  v-if="imagePreview"
                  :src="imagePreview"
                  alt="Preview"
                  class="preview-image"
                />
                <i
                  v-if="imagePreview"
                  @click="removeImage"
                  class="fas fa-circle-minus position-absolute text-danger"
                  style="
                    top: 0;
                    right: -30px;
                    transform: translate(-50%, -50%);
                    font-size: 1.5rem;
                  "
                >
                </i>
              </div>
            </div>
            <div class="col-12 col-md">
              <!-- title -->
              <div class="mt-2">
                <b-form-group id="title" label-for="title">
                  <template #label>
                    <em class="text-danger font-weight-bold">Título: </em>
                  </template>
                  <b-form-input
                    id="title"
                    v-model="newCourse.titulo"
                    placeholder="Titulo del curso"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <!-- descripcion -->
              <div class="mt-2">
                <b-form-group id="textarea" label-for="textarea">
                  <template #label>
                    <em class="text-danger font-weight-bold">Descripción: </em>
                  </template>
                  <b-form-textarea
                    id="textarea"
                    v-model="newCourse.descripcion"
                    placeholder="Descripcion del curso..."
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <!-- categoria del curso -->
              <div class="mt-2">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Categoría:</em>
                  </label>
                  <!-- vue-select -->
                  <div
                    style="
                      background: transparent;
                      padding: 0rem;
                     
                    "
                  >
                    <v-select
                      v-model="newCourse.categoriaCursoId"
                      class="style-chooser"
                      :reduce="(value) => value.id"
                      :options="listaCategoriaCursos"
                      :get-option-label="(option) => `${option.descripcion}`"
                      placeholder="-- seleccionar categoría --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCourse.categoriaCursoId"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay tipos de empresa para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- certificacion -->
              <div class="mt-2">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder"
                      >Certificado Finalización:</em
                    >
                  </label>
                  <!-- vue-select -->
                  <div
                    style="
                      background: transparent;
                      padding: 0rem;
                     
                    "
                  >
                    <v-select
                      v-model="newCourse.certificadoFinalizacion"
                      class="style-chooser"
                      :reduce="(value) => value.value"
                      :options="optionsCertificacion"
                      :get-option-label="(option) => `${option.text}`"
                      placeholder="-- seleccionar una opción --"
                    >
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCourse.certificadoFinalizacion"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay opciones para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- privacidad del curso -->
              <div class="mt-2">
                <div>
                  <label for=" " class="form-label">
                    <em class="text-danger font-weight-bolder">Privacidad:</em>
                  </label>
                  <!-- vue-select -->
                  <div
                    style="
                      background: transparent;
                      padding: 0rem;
                     
                    "
                  >
                    <v-select
                      v-model="newCourse.privacidad"
                      class="style-chooser"
                      :reduce="(value) => value.value"
                      :options="optionsPrivacidadCursos"
                      :get-option-label="(option) => `${option.text}`"
                      placeholder="-- seleccionar una opción --"
                    >
                      <!--  agregar contenido adicional a las opciones-->
                      <template v-slot:option="option">
                        <i :class="`fas fa-${option.icon}`"></i>
                        {{ option.text }}
                      </template>
                      <!-- required -->
                      <template #search="{ attributes, events }">
                        <input
                          class="vs__search"
                          :required="!newCourse.privacidad"
                          v-bind="attributes"
                          v-on="events"
                        />
                      </template>
                      <!-- sin resultados en la busquedad -->
                      <template v-slot:no-options="{ search, searching }">
                        <template v-if="searching">
                          No se encontraron resultados para
                          <em class="text-danger">{{ search }}</em
                          >.
                        </template>
                        <em v-else style="opacity: 0.5"
                          >No hay opciones para mostrar.</em
                        >
                      </template>
                    </v-select>
                  </div>
                </div>
              </div>
              <!-- btn submit -->
              <div class="d-flex justify-content-end mt-2">
                <vs-button
                  animation-type="scale"
                  :loading="isLoading"
                  danger
                   
                  size="large"
                  style="min-width: 150px"
                >
                  <i class="fas fa-circle-plus"></i>
                  <template #animate> Agregar curso </template>
                </vs-button>
              </div>
            </div>
          </b-form>
        </b-tab>
        <b-tab title="Listado de cursos" @click="onResize">
          <b-form-group id="filterForTitle" label-for="filterForTitle">
            <template #label>
              <em class="text-danger font-weight-bold">Filtrar por título: </em>
            </template>
            <b-form-input
              type="search"
              id="filterForTitle"
              placeholder="Empieza la busquedad"
              v-model="searchedTitleCursos"
              required
            ></b-form-input>
          </b-form-group>
          <!-- lista de cursos -->
          <div
            v-if="filterCursosAcademia.length > 0"
            class="row list-videos justify-content-center mt-5 px-2"
          >
            <div
              v-for="(curso, index) in filterCursosAcademia"
              :key="curso.id"
              class="col-12 col-lg-5 d-flex align-items-center py-2 bg-dark rounded"
              :style="{
                transition: 'box-shadow 0.3s ease',
                boxShadow:
                  activeCardVideoCursos == index
                    ? 'inset 0 0 0 2px #186bdd '
                    : 'none',
                cursor: 'default',
              }"
              @click="cardActiveCursos(index)"
            >
              <!-- acciones  -->
              <b-dropdown
                class="position-absolute"
                style="top: 0; right: 0; z-index: 10"
                size="sm"
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
              >
                <template #button-content>
                  <i class="fas fa-ellipsis" style="font-size: 1.5rem"></i>
                </template>
                <b-dropdown-item-button v-b-modal="'modalAdminCurso'"
                  ><i class="fas fa-gear"></i>
                  Administrar</b-dropdown-item-button
                >
                <b-dropdown-item-button v-b-modal="'modalUpdateCurso'"
                  ><i class="fas fa-edit"></i> Editar</b-dropdown-item-button
                >
                <b-dropdown-item-button @click="deleteCursoAcademia(curso.id)"
                  ><i class="fas fa-trash text-danger"></i>
                  <span class="text-danger ml-1"
                    >Eliminar</span
                  ></b-dropdown-item-button
                >
              </b-dropdown>
              <div class="row">
                <div
                  class="col-12 col-lg d-flex align-items-center mt-2 mt-lg-0"
                >
                  <img
                    class="rounded w-100"
                    :src="curso.previewImagenUrl ?? '/img/image_cursos_alt.png'"
                    :alt="curso.titulo"
                  />
                </div>
                <div class="col-12 col-md-8 my-4 content-desc overflow-hidden">
                  <span class="font-weight-bold h4 text-capitalize">{{
                    curso.titulo
                  }}</span>
                  <p class="text-white-50 m-0 custom-descripcion">
                    {{ curso.descripcion }}
                  </p>
                  <vs-tooltip
                    v-if="showReadMoreDesc[index]"
                    class="d-inline"
                    right
                  >
                    <small class="text-capitalize font-italic text-primary">
                      leer más
                    </small>
                    <template #tooltip>
                      <div class="content-tooltip text-left overflow-hidden">
                        <p class="text-break">
                          {{ curso.descripcion }}
                        </p>
                      </div>
                    </template>
                  </vs-tooltip>
                  <!-- <div class="d-flex align-items-center flex-wrap">
                    <b-form-rating
                      id="rating-inline"
                      variant="warning"
                      readonly
                      inline
                      show-value
                      no-border
                      value="3.3"
                      class="bg-transparent p-0"
                      size="sm"
                    ></b-form-rating>
                    <span>(30 calificaciones)</span>
                  </div> -->
                  <div class="d-flex align-items-center">
                    <small class="custom-punto d-flex align-items-center">{{
                      curso.categoriaCurso?.descripcion
                    }}</small>
                    <b-badge
                      :variant="curso.privacidad == 1 ? 'success' : 'danger'"
                    >
                      {{ curso.privacidad == 1 ? "Público" : "Privado" }}
                    </b-badge>
                  </div>
                  <small class="d-flex align-items-center mt-2 text-white-50">
                    <i class="fas fa-circle-check mr-1"></i>
                    última actualización
                    <span class="text-primary ml-1">
                      {{
                        curso.ultimaActualizacion | formatLastUpdateDate
                      }}</span
                    >
                  </small>
                </div>
              </div>
            </div>
          </div>
          <template v-else>
            <div
              class="d-flex flex-column align-items-center justify-content-center"
            >
              <p class="h4 text-white-50">No hay curso para mostrar...</p>
            </div>
          </template>
        </b-tab>
      </b-tabs>
    </div>
    <!-- update curso modal -->
    <b-modal
      id="modalUpdateCurso"
      body-bg-variant=""
      hide-footer
      hide-header
      centered
      size="xl"
      body-class="body-modal-custom"
    >
      <b-form class="row" @submit.prevent="updateCursoAcademia">
        <div
          class="col-12 col-lg mt-2 d-flex flex-column justify-content-center align-items-center"
        >
          <label for=" " class="form-label">
            <em class="text-danger font-weight-bolder">Imagen vista previa:</em>
          </label>
          <!-- image preview -->
          <div class="image-container rounded bg-dark w-100">
            <input
              type="file"
              ref="fileInput"
              @change="previewImage"
              class="file-input"
              @click.stop
            />
            <div
              v-if="
                !imagePreview && !filterDataModalUpdateCurso.previewImagenUrl
              "
              class="placeholder"
              @click="openFileInput"
            >
              Haz clic para seleccionar una imagen
            </div>
            <img
              v-if="imagePreview || filterDataModalUpdateCurso.previewImagenUrl"
              :src="imagePreview || filterDataModalUpdateCurso.previewImagenUrl"
              alt="Preview"
              class="preview-image"
            />
            <i
              v-if="imagePreview || filterDataModalUpdateCurso.previewImagenUrl"
              @click="removeImage"
              class="fas fa-circle-minus position-absolute text-danger"
              style="
                top: 0;
                right: -30px;
                transform: translate(-50%, -50%);
                font-size: 1.5rem;
              "
            >
            </i>
          </div>
        </div>
        <div class="col-12 col-md">
          <!-- title -->
          <div class="mt-2">
            <b-form-group id="title" label-for="title">
              <template #label>
                <em class="text-danger font-weight-bold">Título: </em>
              </template>
              <b-form-input
                id="title"
                v-model="filterDataModalUpdateCurso.titulo"
                placeholder="Titulo del curso"
                required
              ></b-form-input>
            </b-form-group>
          </div>
          <!-- descripcion -->
          <div class="mt-2">
            <b-form-group id="textarea" label-for="textarea">
              <template #label>
                <em class="text-danger font-weight-bold">Descripción: </em>
              </template>
              <b-form-textarea
                id="textarea"
                v-model="filterDataModalUpdateCurso.descripcion"
                placeholder="Descripcion del curso..."
                rows="3"
                max-rows="6"
                required
              ></b-form-textarea>
            </b-form-group>
          </div>
          <!-- categoria del curso -->
          <div class="mt-2">
            <div>
              <label for=" " class="form-label">
                <em class="text-danger font-weight-bolder">Categoría:</em>
              </label>
              <!-- vue-select -->
              <div
                style="
                  background: transparent;
                  padding: 0rem;
                 
                "
              >
                <v-select
                  v-model="filterDataModalUpdateCurso.categoriaCursoId"
                  class="style-chooser"
                  :reduce="(value) => value.id"
                  :options="listaCategoriaCursos"
                  :get-option-label="(option) => `${option.descripcion}`"
                  placeholder="-- seleccionar categoría --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!filterDataModalUpdateCurso.categoriaCursoId"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay tipos de empresa para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!-- certificacion -->
          <div class="mt-2">
            <div>
              <label for=" " class="form-label">
                <em class="text-danger font-weight-bolder"
                  >Certificado Finalización:</em
                >
              </label>
              <!-- vue-select -->
              <div
                style="
                  background: transparent;
                  padding: 0rem;
                 
                "
              >
                <v-select
                  v-model="filterDataModalUpdateCurso.certificadoFinalizacion"
                  class="style-chooser"
                  :reduce="(value) => value.value"
                  :options="optionsCertificacion"
                  :get-option-label="(option) => `${option.text}`"
                  placeholder="-- seleccionar una opción --"
                >
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="
                        !filterDataModalUpdateCurso.certificadoFinalizacion
                      "
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay opciones para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!-- privacidad del curso -->
          <div class="mt-2">
            <div>
              <label for=" " class="form-label">
                <em class="text-danger font-weight-bolder">Privacidad:</em>
              </label>
              <!-- vue-select -->
              <div
                style="
                  background: transparent;
                  padding: 0rem;
                 
                "
              >
                <v-select
                  v-model="filterDataModalUpdateCurso.privacidad"
                  class="style-chooser"
                  :reduce="(value) => value.value"
                  :options="optionsPrivacidadCursos"
                  :get-option-label="(option) => `${option.text}`"
                  placeholder="-- seleccionar una opción --"
                >
                  <!--  agregar contenido adicional a las opciones-->
                  <template v-slot:option="option">
                    <i :class="`fas fa-${option.icon}`"></i>
                    {{ option.text }}
                  </template>
                  <!-- required -->
                  <template #search="{ attributes, events }">
                    <input
                      class="vs__search"
                      :required="!filterDataModalUpdateCurso.privacidad"
                      v-bind="attributes"
                      v-on="events"
                    />
                  </template>
                  <!-- sin resultados en la busquedad -->
                  <template v-slot:no-options="{ search, searching }">
                    <template v-if="searching">
                      No se encontraron resultados para
                      <em class="text-danger">{{ search }}</em
                      >.
                    </template>
                    <em v-else style="opacity: 0.5"
                      >No hay opciones para mostrar.</em
                    >
                  </template>
                </v-select>
              </div>
            </div>
          </div>
          <!-- btn submit -->
          <div class="d-flex justify-content-end mt-2">
            <vs-button
              animation-type="scale"
              :loading="isLoading"
              danger
               
              size="large"
              style="min-width: 150px"
            >
              <i class="fas fa-circle-check"></i>
              <template #animate> Actualizar curso </template>
            </vs-button>
          </div>
        </div>
      </b-form>
    </b-modal>
    <!-- admin curso modal -->
    <b-modal
      id="modalAdminCurso"
      body-bg-variant=""
      size="xl"
      hide-footer
      hide-header
      centered
      body-class="body-modal-custom"
    >
      <b-card bg-variant="dark">
        <div class="row">
          <div class="col-12 col-lg-4 d-flex align-items-center">
            <img
              class="rounded w-100"
              :src="
                filterDataModalUpdateCurso.previewImagenUrl ??
                '/img/image_cursos_alt.png'
              "
              :alt="filterDataModalUpdateCurso.titulo"
            />
          </div>
          <div class="col mt-3 mt-md-0 content-desc overflow-hidden">
            <span class="font-weight-bold h3 text-capitalize"
              >{{ filterDataModalUpdateCurso.titulo }}
            </span>
            <p class="text-white-50 m-0 h4 custom-descripcion mb-1">
              {{ filterDataModalUpdateCurso.descripcion }}
            </p>
            <vs-tooltip
              v-if="
                showReadMoreDesc[
                  listaCursosAcademia.findIndex(
                    (curso) => curso.id == filterDataModalUpdateCurso.id
                  )
                ]
              "
              class="d-inline"
              style="cursor: default"
              right
            >
              <span class="text-capitalize font-italic text-primary">
                leer más
              </span>
              <template #tooltip>
                <div class="content-tooltip text-left overflow-hidden">
                  <p class="text-break h4">
                    {{ filterDataModalUpdateCurso.descripcion }}
                  </p>
                </div>
              </template>
            </vs-tooltip>
            <!--      <div class="d-flex align-items-center flex-wrap">
              <b-form-rating
                id="rating-inline"
                variant="warning"
                readonly
                inline
                show-value
                no-border
                value="3.3"
                class="bg-transparent p-0"
                size="xl"
              ></b-form-rating>
              <span>(30 calificaciones)</span>
            </div> -->
            <div class="d-flex align-items-center">
              <span
                class="custom-punto d-flex align-items-center font-weight-bold"
                >{{
                  filterDataModalUpdateCurso.categoriaCurso?.descripcion
                }}</span
              >
              <b-badge
                :variant="
                  filterDataModalUpdateCurso.privacidad == 1
                    ? 'success'
                    : 'danger'
                "
                >{{
                  filterDataModalUpdateCurso.privacidad == 1
                    ? "Público"
                    : "Privado"
                }}
              </b-badge>
            </div>
            <span class="d-flex align-items-center mt-2 text-white-50">
              <i class="fas fa-circle-check mr-1"></i>
              última actualización
              <span class="text-primary ml-1">
                {{
                  filterDataModalUpdateCurso.ultimaActualizacion
                    | formatLastUpdateDate
                }}</span
              >
            </span>
          </div>
        </div>
      </b-card>
      <div class="row align-items-center">
        <div class="col-12 col-lg-9">
          <b-form-input
            type="search"
            id="filterForTitle"
            placeholder="Filtrar clases por título"
            v-model="searchedTitleClase"
            required
          ></b-form-input>
        </div>
        <div class="col d-flex mt-2 mt-lg-0">
          <vs-button
            v-b-modal="'modalAgregarClase'"
            class="w-100"
             
            style="height: 40px"
            :active="active == 1"
            @click="active = 1"
          >
            <i class="fas fa-circle-plus mr-1" style="font-size: 1.2rem"></i>
            Agregar nueva clase
          </vs-button>
        </div>
      </div>
      <!-- listado de clases -->
      <div class="row list-videos justify-content-center mt-5 px-2">
        <div
          v-for="(clase, index) in filterVideosClase"
          :key="clase.id"
          class="col-12 col-lg-5 d-flex align-items-center py-2 bg-dark rounded"
          :style="{
            transition: 'box-shadow 0.3s ease',
            boxShadow:
              activeCardVideoClase == index
                ? 'inset 0 0 0 2px #186bdd '
                : 'none',
            cursor: 'default',
          }"
          @click="cardActiveClase(index)"
        >
          <!-- acciones  -->
          <b-dropdown
            class="position-absolute"
            style="top: 0; right: 0"
            size="sm"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
          >
            <template #button-content>
              <i class="fas fa-ellipsis" style="font-size: 1.5rem"></i>
            </template>

            <b-dropdown-item-button v-b-modal="'modalUpdateClase'"
              ><i class="fas fa-edit"></i> Editar</b-dropdown-item-button
            >
            <b-dropdown-item-button @click="deleteVideoClase(clase.id)"
              ><i class="fas fa-trash text-danger"></i>
              <span class="text-danger ml-1"
                >Eliminar</span
              ></b-dropdown-item-button
            >
          </b-dropdown>
          <div class="row w-100">
            <div class="col d-flex justify-content-center align-items-center">
              <div
                class="bg-secondary p-2 p-lg-3 rounded-circle"
                style="
                  box-shadow: 0px -1px 33px -8px #d70027;
                  -webkit-box-shadow: 0px -1px 33px -8px #d70027;
                  -moz-box-shadow: 0px -1px 33px -8px #d70027;
                "
              >
                <i class="far fa-circle-play" style="font-size: 2rem"></i>
              </div>
            </div>
            <div class="col-9 my-2 overflow-hidden my-3">
              <h4 class="font-weight-bold text-capitalize">
                {{ clase.titulo }}
              </h4>
              <p class="text-white-50 m-0 custom-descripcion">
                {{ clase.descripcion }}
              </p>
              <!--  <div class="d-flex align-items-center flex-wrap">
                <b-form-rating
                  id="rating-inline"
                  variant="warning"
                  readonly
                  inline
                  show-value
                  no-border
                  value="4.6"
                  class="bg-transparent p-0"
                  size="sm"
                ></b-form-rating>
                <span>(60 calificaciones)</span>
              </div>  -->
              <div
                class="d-flex align-items-center text-white-50 flex-wrap mt-2"
              >
                <i class="fas fa-circle-check mr-1"></i>
                <small class="custom-punto d-flex align-items-center">
                  última actualización
                  <span class="ml-1 text-primary">{{
                    clase.ultimaAcatualizacion | formatLastUpdateDate
                  }}</span>
                </small>
                <i class="fas fa-clock mr-1"></i>
                <code class="text-white">{{ clase.duracion }}h</code>
              </div>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- agregar nueva clase modal -->
    <b-modal
      id="modalAgregarClase"
      body-bg-variant=""
      size="xl"
      hide-footer
      hide-header
      centered
      body-class="body-modal-custom"
    >
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col">
          <b-form @submit.prevent="addClaseCursoAcademia">
            <div class="col-12 col-md">
              <!-- title -->
              <div class="mt-2">
                <b-form-group id="title" label-for="title">
                  <template #label>
                    <em class="text-danger font-weight-bold">Título: </em>
                  </template>
                  <b-form-input
                    id="title"
                    v-model="newClase.titulo"
                    placeholder="Titulo de la clase"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <!-- descripcion -->
              <div class="mt-2">
                <b-form-group id="textarea" label-for="textarea">
                  <template #label>
                    <em class="text-danger font-weight-bold">Descripción: </em>
                  </template>
                  <b-form-textarea
                    id="textarea"
                    v-model="newClase.descripcion"
                    placeholder="Descripcion de la clase..."
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <!-- duracion -->
              <div class="mt-2">
                <b-form-group id="duration" label-for="duration">
                  <template #label>
                    <em class="text-danger font-weight-bold">Duración: </em>
                  </template>
                  <b-form-input
                    id="duration"
                    v-model="newClase.duracion"
                    type="number"
                    placeholder="Duración de la clase"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <!-- url video clase -->
              <b-form-group id="urlVideo" label-for="urlVideo">
                <template #label>
                  <em class="text-danger font-weight-bold">URL video: </em>
                </template>
                <b-form-input
                  type="url"
                  id="urlVideo"
                  v-model="newClase.urlClaseVideo"
                  placeholder="URL de la clase"
                  required
                ></b-form-input>
              </b-form-group>
              <!-- btn submit -->
              <div class="d-flex justify-content-end mt-2">
                <vs-button
                  animation-type="scale"
                  :loading="isLoading"
                  danger
                   
                  size="large"
                  style="min-width: 150px"
                >
                  <i class="fas fa-circle-plus"></i>
                  <template #animate> Agregar clase </template>
                </vs-button>
              </div>
            </div>
          </b-form>
        </div>
        <!-- preview url video-->
        <div
          class="col-12 mr-3 col-lg d-flex justify-content-center align-items-center bg-dark rounded mt-3 mt-lg-0"
        >
          <div
            class="vista-previa d-flex justify-content-center align-items-center rounded"
          >
            <h5>Vista previa</h5>
            <modalIframe
              :url="newClase.urlClaseVideo ?? ''"
              :likeElement="true"
            ></modalIframe>
          </div>
        </div>
      </div>
    </b-modal>
    <!-- update clase modal -->
    <b-modal
      id="modalUpdateClase"
      body-bg-variant=""
      size="xl"
      hide-footer
      hide-header
      centered
      body-class="body-modal-custom"
    >
      <div class="row flex-column-reverse flex-lg-row">
        <div class="col">
          <b-form @submit.prevent="updateClaseCursoAcademia">
            <div class="col-12 col-md">
              <!-- title -->
              <div class="mt-2">
                <b-form-group id="title" label-for="title">
                  <template #label>
                    <em class="text-danger font-weight-bold">Título: </em>
                  </template>
                  <b-form-input
                    id="title"
                    v-model="filterDataModalUpdateClase.titulo"
                    placeholder="Titulo de la clase"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <!-- descripcion -->
              <div class="mt-2">
                <b-form-group id="textarea" label-for="textarea">
                  <template #label>
                    <em class="text-danger font-weight-bold">Descripción: </em>
                  </template>
                  <b-form-textarea
                    id="textarea"
                    v-model="filterDataModalUpdateClase.descripcion"
                    placeholder="Descripcion de la clase..."
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <!-- duracion -->
              <div class="mt-2">
                <b-form-group id="duration" label-for="duration">
                  <template #label>
                    <em class="text-danger font-weight-bold">Duración: </em>
                  </template>
                  <b-form-input
                    id="duration"
                    v-model="filterDataModalUpdateClase.duracion"
                    type="number"
                    placeholder="Duración de la clase"
                    required
                  ></b-form-input>
                </b-form-group>
              </div>
              <!-- url video clase -->
              <b-form-group id="urlVideo" label-for="urlVideo">
                <template #label>
                  <em class="text-danger font-weight-bold">URL video: </em>
                </template>
                <b-form-input
                  type="url"
                  id="urlVideo"
                  v-model="filterDataModalUpdateClase.urlClaseVideo"
                  placeholder="URL de la clase"
                  required
                ></b-form-input>
              </b-form-group>
              <!-- btn submit -->
              <div class="d-flex justify-content-end mt-2">
                <vs-button
                  animation-type="scale"
                  :loading="isLoading"
                  danger
                   
                  size="large"
                  style="min-width: 150px"
                >
                  <i class="fas fa-circle-check"></i>
                  <template #animate> Actualizar clase </template>
                </vs-button>
              </div>
            </div>
          </b-form>
        </div>
        <!-- preview url video-->
        <div
          class="col-12 mr-3 col-lg d-flex justify-content-center align-items-center bg-dark rounded mt-3 mt-lg-0"
        >
          <div
            class="vista-previa d-flex justify-content-center align-items-center rounded"
          >
            <h5>Vista previa</h5>
            <modalIframe
              :url="filterDataModalUpdateClase.urlClaseVideo"
              :likeElement="true"
            ></modalIframe>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import Vue from "vue";
import vSelect from "vue-select";
import modalIframe from "@/components/helexium/modalIframe.vue";
import moment_timezone from "moment-timezone";
Vue.component("v-select", vSelect);
const Swal = require("sweetalert2");
export default {
  name: "Academia_component",
  components: {
    modalIframe,
  },
  data() {
    return {
      persona: {},
      showReadMoreDesc: {},
      listaCategoriaCursos: [],
      optionsCertificacion: [
        {
          text: "Si incluye certificación",
          value: 1,
        },
        {
          text: "No incluye certificación",
          value: 2,
        },
      ],
      optionsPrivacidadCursos: [
        {
          text: "Público ( todas las empresas podrán ver éste curso )",
          value: 1,
          icon: "earth-americas",
        },
        {
          text: "Privado ( sólo tú empresa podrá ver éste curso )",
          value: 2,
          icon: "lock",
        },
      ],
      listaCursosAcademia: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          previewImagenUrl: null,
          privacidad: null,
          responsableId: null,
          responsable: {},
          certificadoFinalizacion: null,
          categoriaCurso: {},
          clases: [],
          categoriaCursoId: null,
          ultimaActualizacion: null,
        },
      ],
      listaVideosClases: [
        {
          id: null,
          titulo: "",
          descripcion: "",
          duracion: "",
          urlClaseVideo: "",
          crusoAcademiaId: null,
          ultimaAcatualizacion: "",
          responsableId: null,
          responsable: {},
          cursoAcademia: {},
        },
      ],
      active: false,
      isLoading: false,
      imagePreview: null,
      file: null,
      fileName: null,
      searchedTitleCursos: "",
      searchedTitleClase: "",
      activeCardVideoCursos: null,
      activeCardVideoClase: null,
      filterDataModalUpdateCurso: {
        id: null,
        titulo: "",
        descripcion: "",
        previewImagenUrl: null,
        privacidad: null,
        responsableId: null,
        responsable: {},
        certificadoFinalizacion: null,
        categoriaCurso: {},
        clases: [],
        categoriaCursoId: null,
        ultimaActualizacion: null,
      },
      newCourse: {
        titulo: "",
        descripcion: "",
        previewImagenUrl: "",
        categoriaCursoId: "",
        certificadoFinalizacion: null,
        privacidad: null,
        responsableId: null,
        ultimaActualizacion: "",
      },
      newClase: {
        titulo: "",
        descripcion: "",
        duracion: "",
        urlClaseVideo: "",
        cursoAcademiaId: null,
        ultimaAcatualizacion: "",
        responsableId: null,
      },
      filterDataModalUpdateClase: {
        id: null,
        titulo: "",
        descripcion: "",
        duracion: "",
        urlClaseVideo: "",
        cursoAcademiaId: null,
        ultimaAcatualizacion: "",
        responsableId: null,
        responsable: {},
        cursoAcademia: {},
      },
    };
  },
  mounted() {
    this.persona = JSON.parse(localStorage.getItem("setPersonaAct"));
    this.getCategoriasCursos();
    this.getListCursosAcademia();
    window.addEventListener("resize", this.onResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    onResize() {
      this.listaCursosAcademia.forEach((_, index) => {
        this.checkDescripcionLength(index);
      });
    },
    async getListClasesCursoAcademia(cursoId) {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `ClasesCursosAcademia/All/${cursoId}`,
        });
        console.log("list clases cursoAcademia...", res);
        if (res.success) {
          return res.data;
        } else {
          return [];
        }
      } catch (error) {
        console.log("err", error);
        return [];
      }
    },
    async getListCursosAcademia() {
      try {
        let res = await this.$store.dispatch("hl_get", {
          path: `${
            this.persona.rol.nivel == 4
              ? "CursosAcademia/"
              : "CursosAcademia/ByResponsableId/" + this.persona.id
          }`,
        });
        console.log("list cursos academia...", res);

        if (res.success) {
          // Obtener todas las promesas de las listas de clases en paralelo
          const clasesPromises = res.data.map((curso) =>
            this.getListClasesCursoAcademia(curso.id)
          );

          // Esperar a que todas las promesas se resuelvan
          const clasesListas = await Promise.all(clasesPromises);

          // Asignar las listas de clases a cada curso
          this.listaCursosAcademia = res.data.map((curso, index) => ({
            ...curso,
            clases: clasesListas[index],
          }));
          console.log(
            "list cursos con clases academia...",
            this.listaCursosAcademia
          );
        } else {
          this.listaCursosAcademia = [];
        }
      } catch (error) {
        this.listaCursosAcademia = [];
        console.log("err", error);
      }
    },
    async addCursoAcademia() {
      try {
        this.isLoading = !this.isLoading;
        const imageUrl = this.imagePreview
          ? await this.uploadVistaPreviaImagenCurso()
          : null;
        this.newCourse = {
          ...this.newCourse,
          responsableId: this.persona.id,
          previewImagenUrl: imageUrl,
          ultimaActualizacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
        console.log("newCourse---------->", this.newCourse);

        let res = await this.$store.dispatch("hl_post", {
          path: "CursosAcademia/",
          data: this.newCourse,
        });
        console.log("res addCourseAcademia--------->", res);
        if (res.success) {
          this.listaCursosAcademia.push({ ...res.data, clases: [] });
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro guardado correctamente!",
          });
          this.initFormNewCourse(); //limpiar formulario
          this.isLoading = !this.isLoading;
        } else {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-xmark'></i>`,
            color: "danger",
            position: "top-right",
            title: "Ha ocurrido un error!",
            text: res.errorDetail,
          });
          this.isLoading = !this.isLoading;
        }
      } catch (error) {
        console.log(error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
        this.isLoading = !this.isLoading;
      }
    },
    async addClaseCursoAcademia() {
      try {
        this.isLoading = !this.isLoading;

        const data = {
          ...this.newClase,
          cursoAcademiaId: this.filterDataModalUpdateCurso.id,
          responsableId: this.persona.id,
          ultimaActualizacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "ClasesCursosAcademia/",
          data,
        });
        console.log("res addClaseCursoAcademia--------->", res);
        if (res.success) {
          this.filterDataModalUpdateCurso.clases.push(res.data);

          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro guardado correctamente!",
          });
          this.initFormNewClase(); //limpiar formulario
          this.isLoading = !this.isLoading;
        } else {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-xmark'></i>`,
            color: "danger",
            position: "top-right",
            title: "Ha ocurrido un error!",
            text: res.errorDetail,
          });
          this.isLoading = !this.isLoading;
        }
      } catch (error) {
        console.log(error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
        this.isLoading = !this.isLoading;
      }
    },
    async updateCursoAcademia() {
      try {
        this.isLoading = !this.isLoading;
        const imageUrl = this.imagePreview
          ? await this.uploadVistaPreviaImagenCurso()
          : this.filterDataModalUpdateCurso.previewImagenUrl;
        this.filterDataModalUpdateCurso = {
          ...this.filterDataModalUpdateCurso,
          previewImagenUrl: imageUrl,
          ultimaActualizacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
        console.log("updateCourse---------->", this.filterDataModalUpdateCurso);

        let res = await this.$store.dispatch("hl_post", {
          path: "CursosAcademia/Update/",
          data: this.filterDataModalUpdateCurso,
        });
        console.log("res updateCourseAcademia--------->", res);
        if (res.success) {
          let index = this.listaCursosAcademia.findIndex(
            (curso) => curso.id == this.filterDataModalUpdateCurso.id
          );
          this.listaCursosAcademia.splice(index, 1, res.data);
          this.onResize();
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro actualizado correctamente!",
          });
          this.isLoading = !this.isLoading;
        } else {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-xmark'></i>`,
            color: "danger",
            position: "top-right",
            title: "Ha ocurrido un error!",
            text: res.errorDetail,
          });
          this.isLoading = !this.isLoading;
        }
      } catch (error) {
        console.log(error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
        this.isLoading = !this.isLoading;
      }
    },
    async updateClaseCursoAcademia() {
      try {
        this.isLoading = !this.isLoading;
        const data = {
          ...this.filterDataModalUpdateClase,
          ultimaActualizacion: this.$moment
            .tz(new Date(), "America/Bogota")
            .format("YYYY-MM-DDTHH:mm:ss"),
        };
        let res = await this.$store.dispatch("hl_post", {
          path: "ClasesCursosAcademia/Update/",
          data,
        });
        console.log("res updateClaseCourseAcademia--------->", res);
        if (res.success) {
          let index = this.filterDataModalUpdateCurso.clases.findIndex(
            (curso) => curso.id == this.filterDataModalUpdateClase.id
          );
          this.filterDataModalUpdateCurso.clases.splice(index, 1, res.data);
          this.onResize();
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-circle-check'></i>`,
            color: "primary",
            position: "top-right",
            title: "Registro actualizado correctamente!",
          });
          this.isLoading = !this.isLoading;
        } else {
          this.$vs.notification({
            // duration: "none",
            progress: "auto",
            icon: `<i class='fas fa-xmark'></i>`,
            color: "danger",
            position: "top-right",
            title: "Ha ocurrido un error!",
            text: res.errorDetail,
          });
          this.isLoading = !this.isLoading;
        }
      } catch (error) {
        console.log(error);
        this.$vs.notification({
          // duration: "none",
          progress: "auto",
          icon: `<i class='fas fa-xmark'></i>`,
          color: "danger",
          position: "top-right",
          title: "Ha ocurrido un error!",
          text: error,
        });
        this.isLoading = !this.isLoading;
      }
    },
    async deleteCursoAcademia(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "#F2135D",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let res = await this.$store.dispatch("hl_get", {
              path: `CursosAcademia/Delete/${id}`,
            });
            console.log("delete cursoAcademia...", res);
            if (res.success) {
              let index = this.listaCursosAcademia.findIndex(
                (curso) => curso.id == id
              );
              this.listaCursosAcademia.splice(index, 1);
              this.$vs.notification({
                //duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-circle-check'></i>`,
                color: "primary",
                position: "top-right",
                title: "Registro eliminado correctamente!",
              });
            } else {
              this.$vs.notification({
                // duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-xmark'></i>`,
                color: "danger",
                position: "top-right",
                title: "Ha ocurrido un error!",
                text: res.errorDetail,
              });
            }
          } catch (error) {
            console.log("err", error);
            this.$vs.notification({
              // duration: "none",
              progress: "auto",
              icon: `<i class='fas fa-xmark'></i>`,
              color: "danger",
              position: "top-right",
              title: "Ha ocurrido un error!",
              text: error,
            });
          }
        }
      });
    },
    async deleteVideoClase(id) {
      Swal.fire({
        title: "Seguro eliminar registro?",
        text: "Después de eliminarlo no podrás recuperarlo!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#186bdd",
        cancelButtonColor: "#F2135D",
        confirmButtonText: "Si!",
        cancelButtonText: "Cancelar",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            let res = await this.$store.dispatch("hl_get", {
              path: `ClasesCursosAcademia/Delete/${id}`,
            });
            console.log("delete claseCursoAcademia...", res);
            if (res.success) {
              let index = this.filterDataModalUpdateCurso.clases.findIndex(
                (clase) => clase.id == id
              );
              this.filterDataModalUpdateCurso.clases.splice(index, 1);
              this.$vs.notification({
                //duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-circle-check'></i>`,
                color: "primary",
                position: "top-right",
                title: "Registro eliminado correctamente!",
              });
            } else {
              this.$vs.notification({
                // duration: "none",
                progress: "auto",
                icon: `<i class='fas fa-xmark'></i>`,
                color: "danger",
                position: "top-right",
                title: "Ha ocurrido un error!",
                text: res.errorDetail,
              });
            }
          } catch (error) {
            console.log("err", error);
            this.$vs.notification({
              // duration: "none",
              progress: "auto",
              icon: `<i class='fas fa-xmark'></i>`,
              color: "danger",
              position: "top-right",
              title: "Ha ocurrido un error!",
              text: error,
            });
          }
        }
      });
    },
    initFormNewCourse() {
      for (let prop in this.newCourse) {
        this.newCourse[prop] = null;
      }
      this.imagePreview = null;
    },
    initFormNewClase() {
      for (let prop in this.newClase) {
        this.newClase[prop] = null;
      }
    },
    openFileInput() {
      this.$refs.fileInput.click();
    },
    previewImage(event) {
      const file = event.target.files[0];
      this.file = file;

      if (file && file.type.startsWith("image/")) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this.imagePreview = e.target.result;
        };

        reader.readAsDataURL(file);
      } else {
        console.error("Por favor, selecciona una imagen.");
      }
    },
    removeImage() {
      this.imagePreview = null;
      this.filterDataModalUpdateCurso.previewImagenUrl = null;
      // Restablecer el valor del input de archivo para permitir seleccionar la misma imagen nuevamente
      this.$refs.fileInput.value = null;
    },
    cardActiveCursos(index) {
      // Cambiar la tarjeta activa
      this.activeCardVideoCursos =
        this.activeCardVideoCursos === index ? null : index;

      // Realizar una copia profunda de los datos
      this.filterDataModalUpdateCurso = {
        ...this.listaCursosAcademia[index],
      };

      console.log(this.filterDataModalUpdateCurso);
    },
    cardActiveClase(index) {
      // Cambiar la tarjeta activa
      this.activeCardVideoClase =
        this.activeCardVideoClase === index ? null : index;

      // Realizar una copia profunda de los datos
      this.filterDataModalUpdateClase = {
        ...this.filterDataModalUpdateCurso.clases[index],
      };

      console.log(this.filterDataModalUpdateClase);
    },
    async getCategoriasCursos() {
      try {
        let res = await this.$store.dispatch("hl_post", {
          path: "CategoriasCursos/ListCategoriasCursos",
        });
        // console.log("------>", res);
        if (res.length > 0) {
          this.listaCategoriaCursos = res;
        }
      } catch (error) {
        this.listaCategoriaCursos = [];
        console.log("err", error);
      }
    },
    async renameFile(nameFile) {
      let collectionBad = "á é í ó ú Á É Í Ó Ú ñ Ñ";
      let collectionGood = "a e i o u A E I O U n N";
      let collectionBadA = collectionBad.split(" ");
      let collectionGoodA = collectionGood.split(" ");
      let count = 0;
      for await (let it of collectionBadA) {
        let pos = nameFile.indexOf(it);
        if (pos > 0) {
          nameFile = nameFile.replaceAll(it, collectionGoodA[count]);
        }
        pos = 0;
        count++;
      }
      return nameFile;
    },
    async uploadVistaPreviaImagenCurso() {
      try {
        this.filename = await this.renameFile(this.file.name); // Math.floor(Math.random() * 9999999999999 + 1);
        // console.log("aaaaaaaaaaaaaaa", this.file, this.filename);
        if (this.filename != null) {
          let nameEmpresa = this.persona.empresa.nombre.replace(/\s/g, "");
          let url = `.|site1|cursosAcademia|${nameEmpresa}|previewImages`;

          let uploadImg = await this.$store.dispatch("onFileCustom", {
            filename: this.filename,
            file: this.file,
            url: url,
          });

          if (uploadImg == true) {
            console.log("archivo subido...", uploadImg);
            return `https://www.helexiumfiles.com/cursosAcademia/${nameEmpresa}/previewImages/${this.filename}`;
          }
        }
      } catch (error) {
        console.log("errror upload", error);
      }
    },
    checkDescripcionLength(index) {
      this.$nextTick(() => {
        const parrafoElement =
          this.$el.querySelectorAll(".content-desc p")[index];

        if (parrafoElement) {
          console.log("Client Height: ", parrafoElement.clientHeight);
          console.log("Scroll Height: ", parrafoElement.scrollHeight);

          if (parrafoElement.scrollHeight > parrafoElement.clientHeight) {
            this.$set(this.showReadMoreDesc, index, true);
          } else {
            this.$set(this.showReadMoreDesc, index, false);
          }
        }
      });
      return false;
    },
  },
  computed: {
    filterCursosAcademia() {
      const listaCursos = this.listaCursosAcademia || [];
      return listaCursos.filter((curso) =>
        curso.titulo
          .toLowerCase()
          .includes(this.searchedTitleCursos.toLowerCase())
      );
    },
    filterVideosClase() {
      const clases = this.filterDataModalUpdateCurso.clases || [];
      return clases.filter((clase) =>
        clase.titulo
          .toLowerCase()
          .includes(this.searchedTitleClase.toLowerCase())
      );
    },
  },
  filters: {
    formatLastUpdateDate(fechaOriginal) {
      return moment_timezone
        .tz(fechaOriginal, "America/Bogota")
        .format("MMM D, YYYY");
    },
  },
};
</script>

<style scoped type="scss">
@import url("https://unpkg.com/vue-select@3.20.2/dist/vue-select.css");
.vista-previa {
  position: relative;
  width: 100%;
  min-height: 300px;
}

.list-videos {
  max-height: 600px;
  overflow-y: scroll;
  gap: 1rem;
}

/* image preview */
.image-container {
  position: relative;
  height: 300px;
  border: 2px dashed #3498db;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  cursor: pointer;
  z-index: 1;
}

.placeholder {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: #777;
  cursor: pointer;
}

.preview-image {
  max-width: 100%;
  max-height: 100%;
  border-radius: 5px;
}

/* parrafo corto */
.custom-descripcion {
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* Número de líneas a mostrar */
  -webkit-box-orient: vertical;
}
</style>
